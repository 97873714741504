import React, { useEffect, useState } from 'react';
import { Box, Typography, makeStyles } from '@material-ui/core';
import SwipeableViews from 'react-swipeable-views';
import Slider from 'react-slick';
import BlogPost from '../molecules/BlogPost';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import axios from 'axios';
import useLastPosts from '../functions/useLastPosts';
// import XMLParser from 'react-xml-parser';
// import xmlData from '../../contants/feed.xml';

// TODO: Pegar postagens diretamente do blog
// const readXml = (xml) => {
// let xmlDoc = xml.responseXML;
//   const parser = new DOMParser();
//   const xmlDoc = parser.parseFromString(xml.responseText, 'application/xml');
//   const response = xmlDoc.getElementsByTagName('channel');
//   console.log(response);
// };

// useEffect(() => {
// const xml = new XMLParser().parseFromString();
// console.log(xml);
// const xmlDoc = new XMLHttpRequest();
// xmlDoc.open('GET', 'test.xml', true);
// xmlDoc.timeout = 2000;
// xmlDoc.onload = () => xmlDoc.responseXML;
// xmlDoc.send(null);
// console.log(xmlDoc);
// const xmlData = xmlDoc.responseText;
// console.log(xmlData);
// readXml(xmlDoc);
// });

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTypography-h4': {
      paddingBottom: '25px',
    },
  },
  posts: {
    display: 'grid',
    gridTemplateColumns: 'auto auto auto auto',
    gap: '20px',

    '@media screen and (max-width: 1200px)': {
      gridTemplateColumns: 'auto auto',
    },
    '@media screen and (max-width: 620px)': {
      gridTemplateColumns: 'auto',
    },
  },
  mobilePosts: {
    display: 'none',

    '& .slick-slide > div': {
      margin: '0 5px',
      display: 'flex',
      alignSelf: 'stretch',
      width: '100%',
    },
    '& .slick-list': {
      margin: '0 -5px',
    },
    '& .slick-track': {
      display: 'flex',
      alignItems: 'stretch',
    },
    '& .slick-slide': {
      display: 'flex',
      alignSelf: 'stretch',
      height: 'unset',
    },
    '& .slick-dots li button:before': {
      marginTop: '8px',
      color: theme.palette.primary.main,
    },
  },

  '@media screen and (max-width: 959px)': {
    posts: {
      display: 'none',
    },
    mobilePosts: {
      display: 'inline',
    },
  },
}));

const BlogHighlights = () => {
  const classes = useStyles();
  const { posts, getLastPosts } = useLastPosts();
  useEffect(() => {
    getLastPosts();
  }, []);

  const renderPosts = () => (
    posts.map((topic) => {
      const { excerpt } = topic;
      const sentence = excerpt.rendered.substring(3, (excerpt.rendered.length) - 5);
      return (
        <BlogPost
          link={topic.link}
          rel="noopener noreferrer"
          key={topic.id}
          img={topic.image}
          title={topic.title.rendered}
          description={sentence}
        />
      );
    })
  );

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };

  return (
    <Box className={classes.root}>
      <Typography variant="h4" component="h2">Conheça nosso blog</Typography>
      {/* TODO: pós o merge da otimização, pode colocar aqui o ShowInmobile, para evitar renderização desnecessária */}
      <Box className={classes.posts}>
        {renderPosts()}
      </Box>
      <Box className={classes.mobilePosts}>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <div id="getHeight">
          <Slider {...settings}>
            {renderPosts()}
          </Slider>
        </div>
      </Box>
    </Box>
  );
};

export default BlogHighlights;
