import React from 'react';
import { Box, Typography, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

import Img from 'gatsby-image';
import StyledButton from '../atoms/StyledButton';
import IconRightArrow from '../atoms/IconRightArrow';
import TutorialStep from '../atoms/TutorialStep';
import StepsBackground from '../atoms/StepsBackground';
import LazyImage from '../atoms/LazyImage';
import { getImage } from '../functions/useStaticImages';
//  import Sticker1 from '../../static/images/mulher-notebook-foguete.png';
//  import Sticker2 from '../../static/images/pessoas-conversando-caixa-dialogo.png';
//  import Sticker3 from '../../static/images/homem-notebook-camisa-verde.png';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .title': {
      marginBottom: '20px',

      '& .MuiTypography-root': {
        color: theme.palette.midGrey.main,

        '& span': {
          color: theme.palette.success.main,
        },
      },

      '& .MuiTypography-h4': {
        marginBottom: '15px',
      },
    },
  },
  titleDefault: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',

    '& .MuiTypography-h6': {
      width: '70%',

      '@media screen and (max-width: 1080px)': {
        width: '100%',
      },
    },
  },
  titlePageCompany: {
    width: '45%',

    '@media screen and (max-width: 1080px)': {
      width: '100%',
      textAlign: 'center',
    },
  },
  steps: {
    display: 'grid',
    gridTemplateColumns: '25% auto 32% auto 26%',
    marginBottom: '50px',

    '@media screen and (max-width: 1080px)': {
      gridTemplateColumns: 'auto',
      gap: '20px',
    },
  },
  step: {
    '& .sticker': {
      textAlign: 'center',
      marginBottom: '30px',

      '@media screen and (max-width: 1080px)': {
        display: 'none',
      },
    },
  },
  arrow: {
    textAlign: 'center',
    alignSelf: 'center',

    '& svg path': {
      fill: '#C9C9DF',
    },

    '@media screen and (max-width: 1080px)': {
      display: 'none',
    },
  },
  cardText: {
    '& .MuiTypography-h4': {
      marginBottom: '10px',
    },
  },
  cardButton: {
    display: 'grid',
    justifyContent: 'end',

    '& .MuiButton-root': {
      width: '370px',
      marginBottom: '20px',
      color: theme.palette.success.main,
    },
    '& .MuiTypography-body1': {
      width: '370px',
    },

    '@media screen and (max-width: 1080px)': {
      display: 'block',

      '& .MuiButton-root, & .MuiTypography-body1': {
        width: '100%',
      },
    },
  },
}));

const SwitchAccountantStepByStep = ({ home, onClick }) => {
  const classes = useStyles();
  const images = getImage();

  const Sticker1 = images.allImageSharp.nodes.find((n) => n.fixed.originalName === 'mulher-notebook-foguete.png').fixed;
  const Sticker2 = images.allImageSharp.nodes.find((n) => n.fixed.originalName === 'pessoas-conversando-caixa-dialogo.png').fixed;
  const Sticker3 = images.allImageSharp.nodes.find((n) => n.fixed.originalName === 'homem-notebook-camisa-verde.png').fixed;

  const buttonProps = home ? {
    component: 'a',
    href: '/abrir-empresa/',
  } : { onClick };

  return (
    <Box className={classes.root}>
      <Box className={`title ${home ? classes.titleDefault : classes.titlePageCompany}`}>
        <Typography variant="h4" component="h2">
          <span>Trazer sua contabilidade </span>
          para a Conube é simples e seguro
        </Typography>

        <Typography variant="h6" component="h3">
          Você pode trocar de contador a qualquer momento do ano, sem custos!
          Confira como nosso passo a passo é fácil e rápido.
        </Typography>
      </Box>

      <Box className={classes.steps}>
        <Box className={classes.step}>
          <Box className="sticker">
            {/* <img src={Sticker1} alt="Pessoa fazendo cadastro" style={{ marginBottom: '7px' }} /> */}
            {/* <LazyImage src={Sticker1} alt="Pessoa fazendo cadastro" style={{marginBottom: '7px'}} /> */}
            <LazyImage>
              <Img fixed={Sticker1} alt="Pessoa fazendo cadastro" style={{ marginBottom: '7px' }} />
            </LazyImage>
          </Box>

          <TutorialStep
            step={1}
            title="Solicite uma proposta"
            text="Para conhecermos melhor o perfil da sua empresa, preencha nosso formulário de troca de contador."
            color="success"
          />
        </Box>

        <Box className={classes.arrow}>
          <IconRightArrow />
        </Box>

        <Box className={classes.step}>
          <Box className="sticker">
            {/* <img src={Sticker2} alt="Especialista disposto a atender" style={{ marginBottom: '19px' }} /> */}
            {/* <LazyImage img={Sticker2} alt="Especialista disposto a atender" style={{marginBottom: '19px'}} /> */}
            <LazyImage>
              <Img fixed={Sticker2} alt="Especialista disposto a atender" style={{ marginBottom: '19px' }} />
            </LazyImage>
          </Box>

          <TutorialStep
            step={2}
            title="Fale com nossos especialistas"
            text="Um de nossos consultores fará uma revisão do atual cenário contábil e fiscal da sua empresa."
            color="success"
          />
        </Box>

        <Box className={classes.arrow}>
          <IconRightArrow />
        </Box>

        <Box className={classes.step}>
          <Box className="sticker">
            {/* <img src={Sticker3} alt="Pessoa escolhendo um modelo ideal" /> */}
            {/* <LazyImage img={Sticker3} alt="Pessoa escolhendo modelo ideal" /> */}
            <LazyImage>
              <Img fixed={Sticker3} alt="Pessoa escolhendo modelo ideal" />
            </LazyImage>

          </Box>

          <TutorialStep
            step={3}
            title="Envie a documentação"
            text="Faça o envio de seus documentos com toda a segurança por meio da nossa plataforma."
            color="success"
          />
        </Box>
      </Box>

      <StepsBackground color="success">
        <Box className={classes.cardText}>
          <Typography variant="h4" component="h3">Tudo pronto!</Typography>

          <Typography variant="body1">
            Em pouco tempo você terá a contabilidade da sua empresa 100%
            digital, com toda a praticidade e segurança.
          </Typography>
        </Box>

        <Box className={classes.cardButton}>
          <StyledButton
            text="Trocar de contador"
            variant="contained"
            color="white"
            {...buttonProps}
          />
          <Typography variant="body1">
            Fale conosco e saiba como a Conube pode ajudar sua empresa.
          </Typography>
        </Box>
      </StepsBackground>
    </Box>
  );
};

SwitchAccountantStepByStep.propTypes = {
  home: PropTypes.bool,
  onClick: PropTypes.func,
};

SwitchAccountantStepByStep.defaultProps = {
  home: false,
  onClick: null,
};

export default SwitchAccountantStepByStep;
