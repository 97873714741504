import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { useNetworkStatus } from 'react-adaptive-hooks/network';
import Employee1 from '../../static/images/employee-png/funcionario-conube-1.png';
import Employee2 from '../../static/images/employee-png/funcionario-conube-2.png';
import Employee3 from '../../static/images/employee-png/funcionario-conube-3.png';
import Employee4 from '../../static/images/employee-png/funcionario-conube-4.png';
import Employee5 from '../../static/images/employee-png/funcionario-conube-5.png';
import Employee6 from '../../static/images/employee-png/funcionario-conube-6.png';
import Employee7 from '../../static/images/employee-png/funcionario-conube-7.png';
import Employee8 from '../../static/images/employee-png/funcionario-conube-8.png';
import EmployeeWebp1 from '../../static/images/employee-webp/funcionario-conube-1.webp';
import EmployeeWebp2 from '../../static/images/employee-webp/funcionario-conube-2.webp';
import EmployeeWebp3 from '../../static/images/employee-webp/funcionario-conube-3.webp';
import EmployeeWebp4 from '../../static/images/employee-webp/funcionario-conube-4.webp';
import EmployeeWebp5 from '../../static/images/employee-webp/funcionario-conube-5.webp';
import EmployeeWebp6 from '../../static/images/employee-webp/funcionario-conube-6.webp';
import EmployeeWebp7 from '../../static/images/employee-webp/funcionario-conube-7.webp';
import EmployeeWebp8 from '../../static/images/employee-webp/funcionario-conube-8.webp';
import SquareEmojiHands from '../../static/images/quadrado-maos-alto.png';
import SquareEmojiBeach from '../../static/images/quadrado-praia.png';
import PurpleHeart from '../../static/images/coracao-roxo.png';

const useStyles = makeStyles({
  root: {
    display: 'grid',
    gridTemplateColumns: 'min-content min-content min-content min-content',
    gap: '8px',
    paddingTop: '40px',
    float: 'right',

    '& .MuiBox-root, & div': {
      width: '140px',
      height: '140px',
    },
    '& .MuiBox-root': {
      background: '#573DA8',
      border: '1px solid #8645FF',
      borderRadius: '12px',
    },

    '@media screen and (max-width: 1260px)': {
      '& .MuiBox-root, & div, & img': {
        width: '120px',
        height: '120px',
      },
    },
  },
  heart: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '& img': {
      width: '30px',
      height: '30px',
    },
  },
});

const EmployeePictures = () => {
  const classes = useStyles();

  const { effectiveConnectionType } = useNetworkStatus();

  function getImageByConnection(firstOption, secondOption) {
    const option = ['2g', 'slow-2g'].includes(effectiveConnectionType) ? secondOption : firstOption;
    return <img src={option} alt="Foto de funcionário da Conube" width="142px" height="142px" />;
  }

  return (
    <Box className={classes.root}>
      <Box />
      {getImageByConnection(Employee1, EmployeeWebp1)}
      {getImageByConnection(Employee2, EmployeeWebp2)}
      <img src={SquareEmojiHands} alt="Emoji mãos para o alto comemorando" width="142px" height="142px" />

      {getImageByConnection(Employee3, EmployeeWebp3)}
      <div className={classes.heart}>
        <img src={PurpleHeart} alt="Coração roxo" width="142px" height="142px" />
      </div>
      {getImageByConnection(Employee4, EmployeeWebp4)}
      <Box />

      {getImageByConnection(Employee5, EmployeeWebp5)}
      <Box />
      {getImageByConnection(Employee6, EmployeeWebp6)}
      {getImageByConnection(Employee7, EmployeeWebp7)}

      <div />
      {getImageByConnection(Employee8, EmployeeWebp8)}
      <img src={SquareEmojiBeach} alt="Emoji praia" width="142px" height="142px" />
    </Box>
  );
};

export default EmployeePictures;
