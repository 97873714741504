import React, { useState } from 'react';
import {
  Box,
  Tabs,
  Tab,
  Typography,
  makeStyles,
} from '@material-ui/core';
import SwipeableViews from 'react-swipeable-views';

import OpenCompanyStepByStep from '../molecules/OpenCompanyStepByStep';
import SwitchAccountantStepByStep from '../molecules/SwitchAccountantStepByStep';

const ProcessSteps = () => {
  const [tabValue, setTabValue] = useState(0);

  const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginBottom: '100px',

      '@media screen and (max-width: 959px)': {
        marginBottom: '0px',
      },
    },
    tabs: {
      width: '600px',
      backgroundColor: '#F7F7FA',
      borderRadius: '16px',
      marginBottom: '50px',

      '@media screen and (max-width: 959px)': {
        width: '100%',
      },

      '& .MuiTab-root': {
        padding: '25px 30px',
        maxWidth: 'unset',
        color: theme.palette.midGrey.main,
        textTransform: 'none',
      },
      '& .Mui-selected .MuiTypography-root': {
        color: tabValue === 0 ? theme.palette.info.highlight : theme.palette.success.main,
      },
      '& .MuiTabs-indicator': {
        backgroundColor: tabValue === 0 ? theme.palette.info.highlight : theme.palette.success.main,
      },

      '& .text-desktop': {
        '@media screen and (max-width: 959px)': {
          display: 'none',
        },
      },
      '& .text-mobile': {
        '@media screen and (min-width: 959px)': {
          display: 'none',
        },
      },
    },
    swipeable: {
      width: '100%',
    },
  }));
  const classes = useStyles();

  const renderTabText = (desktopText, mobileText) => (
    <>
      <Typography variant="body1" className="text-desktop">{desktopText}</Typography>
      <Typography variant="body1" className="text-mobile">{mobileText}</Typography>
    </>
  );

  return (
    <Box className={classes.root}>
      <Tabs
        value={tabValue}
        onChange={(event, value) => setTabValue(value)}
        centered
        className={classes.tabs}
      >
        <Tab
          label={renderTabText('Quero abrir uma empresa', 'Abrir empresa')}
          disableRipple
          value={0}
        />
        <Tab
          label={renderTabText('Quero trocar de contador', 'Trocar de contador')}
          disableRipple
          value={1}
        />
      </Tabs>

      <SwipeableViews index={tabValue} onChangeIndex={setTabValue} className={classes.swipeable}>
        <OpenCompanyStepByStep home />
        <SwitchAccountantStepByStep home />
      </SwipeableViews>
    </Box>
  );
};

export default ProcessSteps;
