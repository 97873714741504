import React from 'react';

const ArrowAdornment = () => (
  <svg className="arrow-adornment" width="100" height="29" viewBox="0 0 100 29" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M97.9965 3.0957C77.7743 3.0957 70.4757 25.0957 50.4822 25.0957C30.4888 25.0957 27.8317 3.0957 2.96792 3.0957C-21.8959 3.0957 118.219 3.0957 97.9965 3.0957Z" fill="#F5F5F8"/>
    <path className="arrow" fill-rule="evenodd" clip-rule="evenodd" d="M44.7133 11.1436C45.055 10.8019 45.609 10.8019 45.9507 11.1436L49.9987 15.1916L54.0466 11.1436C54.3884 10.8019 54.9424 10.8019 55.2841 11.1436C55.6258 11.4853 55.6258 12.0393 55.2841 12.381L50.6174 17.0477C50.2757 17.3894 49.7217 17.3894 49.38 17.0477L44.7133 12.381C44.3716 12.0393 44.3716 11.4853 44.7133 11.1436Z" fill="#31AEFF"/>
  </svg>
);

export default ArrowAdornment;
