import React, { useEffect } from 'react';
import Main from '../components/pages/Main';
import PageHome from '../components/pages/Home';

const Home = () => {
  return (
    <Main page="home">
      <PageHome />
    </Main>
  )
};

export default Home;
