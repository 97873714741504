import React from 'react';
import { Box, Typography, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

const colors = {
  info: {
    font: '#306E97',
    background: '#D2E7F4',
  },
  success: {
    font: '#30746A',
    background: '#CEF0EB',
  },
  purple: {
    font: '#573da8',
    background: '#DED6F8'
  }
};

const TutorialStep = ({ step, title, text, color }) => {
  const useStyles = makeStyles(() => ({
    root: {
      display: 'flex',
      alignSelf: 'center',
  
      '& .stepText': {
        background: colors[color].background,
        color: colors[color].font,
        padding: '5px 12px',
        borderRadius: '100px',
        alignSelf: 'start',
      },
  
      '@media screen and (max-width: 1080px)': {
        display: 'grid',
        justifyItems: 'center',
      },
    },
    description: {
      display: 'flex',
      flexDirection: 'column',
      padding: '0 0 0 20px',
      gap: '15px',
      
      '@media screen and (max-width: 1080px)': {
        padding: '20px 0 0 0',
        textAlign: 'center',
      },
    },
  }));
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Typography variant="body1" className="stepText">{step}</Typography>
      <Box className={classes.description}>
        <Typography variant="h6" component="h4">{title}</Typography>
        <Typography variant="body1">{text}</Typography>
      </Box>
    </Box>
  );
};

TutorialStep.propTypes = {
  step: PropTypes.number,
  title: PropTypes.string,
  text: PropTypes.string,
  color: PropTypes.string,
};
TutorialStep.defaultProps = {
  step: 1,
  title: '',
  text: '',
  color: 'info',
};

export default TutorialStep;
