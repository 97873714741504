import React, { useRef } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import ArrowAdornment from './ArrowAdornment';
import useIntersector from './UseIntersector';

const background = {
  info: {
    backgroundColor: '#306E97',
    backgroundImage: `url(/images/steps-background-blue.png)`,
  },
  success: {
    backgroundColor: '#30746A',
    backgroundImage: `url(/images/steps-background-green.png)`,
  },
};

const StepsBackground = ({ color, children, ...rest }) => {

  const reference = useRef();

  const isIntersecting = useIntersector({ref:reference});

  const useStyles = makeStyles((theme) => ({
    root: {
      display: 'grid',
      gridTemplateColumns: '35% 65%',
      padding: '40px 70px 30px 150px',
      borderRadius: '16px',
      position: 'relative',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundColor: background[color].backgroundColor,
      backgroundImage : isIntersecting ? background[color].backgroundImage : '',

      '& .MuiTypography-root': {
        color: theme.palette.secondary.contrastText,
      },

      '& .arrow-adornment': {
        position: 'absolute',
        top: '-5px',
        left: '50%',
        transform: 'translateX(-50%)',

        '& .arrow': {
          fill: theme.palette[color].main,
        },
      },

      '@media screen and (max-width: 1080px)': {
        gridTemplateColumns: 'auto',
        gap: '20px',
        padding: '55px 15px',
        textAlign: 'center',
      },
    },
  }));
  const classes = useStyles();

  return (
    <>
      <div ref={reference} />
      <Box className={classes.root} {...rest}>
        <ArrowAdornment />
        {children}
      </Box>
    </>
  );
};

StepsBackground.defaultProps = {
  color: 'blue',
  children: <></>,
  rest: {},
};

StepsBackground.propTypes = {
  color: PropTypes.string,
  children: PropTypes.element,
  rest: PropTypes.shape()
};

export default StepsBackground;
