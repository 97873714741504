import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Text from '../atoms/Text';
import LazyImage from '../atoms/LazyImage';

const Container = styled.div`
  display: flex;
  flex: 1;
  margin: 15px 0;
  align-items: center;
`;

const ServiceType = styled.div`
  flex: 3;
  text-align: left;
  padding: 0 10px;
  //padding: 0 10px;
`;

const ServiceImage = styled.img`
  align-self: center;
  width: 85px;
`;

const ServiceItem = ({ img, alt, text }) => (
  <Container>
    <div style={{ flex: 1 }}>
      {/* <ServiceImage src={img} alt={alt} width="85px" height="75px" /> */}
      <LazyImage img={img} alt={alt} width="85px" height="75px" style={{alignSelf: 'center', width: '85px'}} />
    </div>
    <ServiceType>
      <Text size="20px">
        {text}
      </Text>
    </ServiceType>
  </Container>
);

ServiceItem.propTypes = {
  img: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default ServiceItem;
