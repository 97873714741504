import React from 'react';
import {
  Grid,
  Typography,
  makeStyles,
} from '@material-ui/core';
import HeroBackground from '../molecules/HeroBackground';
import StyledButton from '../atoms/StyledButton';
import EmployeePictures from '../atoms/EmployeePictures';
import Spacer from '../atoms/Spacer';
import ShowInMobile from '../atoms/ShowInMobile';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',

    '& .span': {
      color: `${theme.palette.info.main} !important`,
    },

    '& .title strong': {
      color: theme.palette.info.highlight,
    },
  },
  description: {
    maxWidth: '390px',
  },
  button: {
    textDecoration: 'none',
    maxWidth: '65%',
    
    '@media screen and (max-width: 959px)': {
      maxWidth: '85%',
    },
  },
  photos: {
    '@media screen and (max-width: 959px)': {
      display: 'none',
    },
  }
}));

const HomeHero = () => {
  const classes = useStyles();

  return (
    <HeroBackground page="home">
      <Grid container spacing={2} className={classes.root}>
        <Grid item md={6} xs={12} className="text">
          <Typography className="span">
            Nascemos com um propósito
          </Typography>

          <Typography variant="h3" component="h1" className="title">
            Somos uma <strong>contabilidade</strong><br />
            <strong> digital feita por pessoas</strong> e <br />
            para pessoas
          </Typography>

          <Typography variant="body1" component="h2" className={classes.description}>
            Tenha a praticidade de uma contabilidade digital com
            a credibilidade de um atendimento humanizado
          </Typography>

          <Spacer size={20} />

          <a href="/quanto-custa/" className={classes.button}>
            <StyledButton
              text="Saiba quanto custa"
              variant="contained"
              color="secondary"
              width="100%"
            />
          </a>
        </Grid>

        <ShowInMobile breakpoint={959}>
          <Grid item md={6} xs={12} className={classes.photos}>
            <EmployeePictures />
          </Grid>
        </ShowInMobile>
      </Grid>
    </HeroBackground>
  );
};

export default HomeHero;
