import React from 'react';
import {
  Box,
  Typography,
  makeStyles,
} from '@material-ui/core';
import ServiceItem from './ServiceItem';
import IconCalculator from '../../static/images/svg/calculator.svg';
import IconGraphics from '../../static/images/graphics.svg';
import IconEmail from '../../static/images/svg/email.svg';
import IconCalendar from '../../static/images/svg/calendar.svg';
import IconUserInfos from '../../static/images/svg/companyInfos.svg';
import IconBenefits from '../../static/images/svg/benefits.svg';
import StyledButton from '../atoms/StyledButton';

const useStyles = makeStyles((theme) => ({
  root: {
    borderBottom: '2px solid #573DA826',
    paddingBottom: '50px',
    
    '& .MuiTypography-h4': { marginBottom: '25px' },
    '& .MuiTypography-h6': { color: theme.palette.midGrey.main },
    
    '@media screen and (max-width: 900px)': {
      borderBottom: 0,
      paddingBottom: 0,
      '& .MuiTypography-h4': { marginBottom: '20px' },
    },
  },
  subtitle: {
    display: 'grid',
    gridTemplateColumns: '70% 30%',
    alignItems: 'center',
    marginBottom: '50px',

    '& .MuiTypography-body1': {
      textAlign: 'right',
    },

    '@media screen and (max-width: 900px)': {
      marginBottom: '40px',
      gridTemplateColumns: 'auto',

      '& .MuiTypography-body1': {
        display: 'none',
      },
    },
  },
  services: {
    display: 'grid',
    gridTemplateColumns: 'auto auto auto',
    gridAutoRows: '1fr',
    alignSelf: 'stretch',

    '@media screen and (max-width: 900px)': {
      gridTemplateColumns: 'auto',
    }
  },
  button: {
    textAlign: 'center',
    marginTop: '20px',
    
    '& .MuiButtonBase-root': { width: '300px' },
    
    '@media screen and (max-width: 900px)': {
      marginTop: '10px',
      '& .MuiButtonBase-root': { width: '100%' },
    },
  },
}));

const ListOfServices = () => {
  const classes = useStyles();

  const services = [
    { text: 'Emissão de notas fiscais simplificada', img: IconCalculator, alt: 'Ilustração de calculadora' },
    { text: 'Gestão financeira sem complicações', img: IconGraphics, alt: 'Ilustração de gráficos' },
    { text: 'Previsão  e detalhamento de impostos', img: IconEmail, alt: 'Ilustração de e-mail' },
    { text: 'Calendário e notificações de atividades', img: IconCalendar, alt: 'Ilustração de calendário' },
    { text: 'Todas as informações da sua empresa em um só lugar', img: IconUserInfos, alt: 'Ilustração de perfil de usuário' },
    { text: 'Benefícios e parcerias exclusivas', img: IconBenefits, alt: 'Ilustração de benefícios' },
  ];

  return (
    <Box className={classes.root}>
      <Typography variant="h4" component="h2">
        Tenha mais tempo para cuidar do seu negócio
      </Typography>

      <Box className={classes.subtitle}>
        <Typography variant="h6" component="h3">
          Simplifique suas rotinas contábeis e fiscais com a ajuda da nossa equipe de especialistas
        </Typography>
        <Typography variant="body1">
          Diga adeus à papelada 👋 
        </Typography>
      </Box>

      <Box className={classes.services}>
        {services.map((item) => <ServiceItem key={item.text} img={item.img} alt={item.alt} text={item.text} />)}
      </Box>

      <Box className={classes.button}>
        <StyledButton
          text="Saiba quanto custa"
          variant="outlined"
          color="primary"
          component="a"
          href="/quanto-custa/"
        />
      </Box>
    </Box>
);
};

export default ListOfServices;
