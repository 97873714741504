import React, { useEffect, useRef, useState } from 'react';
import { Box, Typography, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import IconArrow from '../../static/images/seta-direita.svg';
import LazyImage from '../atoms/LazyImage';

const useStyles = makeStyles((theme) => ({
  root: {
    // minHeight: '450px',
    borderRadius: '16px',
    overflow: 'hidden',
    backgroundColor: theme.palette.secondary.contrastText,
    position: 'relative',
    paddingBottom: '40px',

    // '@media screen and (max-width: 600px)': {
    //   height: '450px',
    // },

    '& .image-wrap': {
      height: '180px',
      position: 'relative',
    },

    '& .button': {
      width: '90%',
      display: 'grid',
      gridTemplateColumns: 'auto auto',
      alignItems: 'center',
      position: 'absolute',
      bottom: '25px',

      '& .MuiTypography-button': { color: theme.palette.primary.main },
      '& img': {
        justifySelf: 'right',
        paddingRight: '10px',
        transition: '0.2s',
      },
    },

    '&:hover': {
      cursor: 'pointer',

      '& .image-wrap': {
        '& .MuiBox-root': {
          opacity: 0,
          transition: '0.2s',
        },
      },
      '& .button': {
        '& div': {
          paddingRight: 0,
          transition: '0.2s',
        },
      },
    },
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  gradient: {
    width: '100%',
    height: '140px',
    position: 'absolute',
    bottom: 0,
    opacity: 0.3,
    background: 'linear-gradient(180deg, #FFF0 0%, #8645FF 100%), #FFF0',
    transition: '0.2s',
  },
  content: {
    padding: '25px 15px',
    height: '100%',
    '& .MuiTypography-body1': {
      overflow: 'hidden',
      paddingTop: '15px',
    },
  },
}));

const BlogPost = ({
  key, img, title, description, link,
}) => {
  const classes = useStyles();
  const linkRef = useRef();

  return (
    <Box
      onClick={() => linkRef.current.click()}
      className={classes.root}
      key={key}
    >
      <Box className="image-wrap">
        {/* <img src={img} alt="Postagem do blog" className={classes.image} /> */}
        <LazyImage src={img} alt="Postagemdo blog" className={classes.image} />
        <Box className={classes.gradient} />
      </Box>

      <Box className={classes.content}>
        <Typography variant="h6" component="h3">{title}</Typography>
        {/* <Typography variant="body1">{description}</Typography> */}
        <Typography variant="body1" className={classes}>{description}</Typography>

        <Box className="button">
          <Typography variant="button">Saiba mais</Typography>
          <img src={IconArrow} alt="Seta para redirecionar" />
        </Box>
      </Box>

      <a target="_blank" ref={linkRef} href={link} style={{ display: 'none' }} rel="noreferrer" />
    </Box>
  );
};

BlogPost.propTypes = {
  key: PropTypes.string,
  img: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  link: PropTypes.string.isRequired,
};
BlogPost.defaultProps = {
  key: '',
  img: '',
  title: '',
  description: '',
};

export default BlogPost;
